import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _081fb5c2 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _fca0ffc8 = () => interopDefault(import('../src/pages/agreement.vue' /* webpackChunkName: "pages/agreement" */))
const _6d98b6a8 = () => interopDefault(import('../src/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _08446a15 = () => interopDefault(import('../src/pages/flights.vue' /* webpackChunkName: "pages/flights" */))
const _1ba7ffe4 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _081fb5c2,
    meta: {"name":"about-page","transition":"fade"},
    name: "about"
  }, {
    path: "/agreement",
    component: _fca0ffc8,
    meta: {"name":"agreement-page","transition":"fade"},
    name: "agreement"
  }, {
    path: "/faq",
    component: _6d98b6a8,
    meta: {"name":"faq-page","transition":"fade"},
    name: "faq"
  }, {
    path: "/flights",
    component: _08446a15,
    meta: {"name":"flights","transition":"fade"},
    name: "flights"
  }, {
    path: "/",
    component: _1ba7ffe4,
    meta: {"name":"index","transition":"fade"},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
