import Vue from 'vue';
import {mapGetters} from 'vuex';

Vue.mixin({
  computed: {
    ...mapGetters(['isMobile']),
    isDev() {
      return !/\/\/api.razlet.ru/.test(process.env.asb3URL);
    },
  },
});