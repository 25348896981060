export const FaqBlock = () => import('../../src/components/faq-block.vue' /* webpackChunkName: "components/faq-block" */).then(c => wrapFunctional(c.default || c))
export const MainFooter = () => import('../../src/components/main-footer.vue' /* webpackChunkName: "components/main-footer" */).then(c => wrapFunctional(c.default || c))
export const MainHeader = () => import('../../src/components/main-header.vue' /* webpackChunkName: "components/main-header" */).then(c => wrapFunctional(c.default || c))
export const PopularDirections = () => import('../../src/components/popular-directions.vue' /* webpackChunkName: "components/popular-directions" */).then(c => wrapFunctional(c.default || c))
export const ReturnBlock = () => import('../../src/components/return-block.vue' /* webpackChunkName: "components/return-block" */).then(c => wrapFunctional(c.default || c))
export const SupportBlock = () => import('../../src/components/support-block.vue' /* webpackChunkName: "components/support-block" */).then(c => wrapFunctional(c.default || c))
export const IconsAirplane = () => import('../../src/components/icons/airplane.vue' /* webpackChunkName: "components/icons-airplane" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckTicketIcon = () => import('../../src/components/icons/check-ticket-icon.vue' /* webpackChunkName: "components/icons-check-ticket-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCheck = () => import('../../src/components/icons/check.vue' /* webpackChunkName: "components/icons-check" */).then(c => wrapFunctional(c.default || c))
export const IconsCurrency = () => import('../../src/components/icons/currency.vue' /* webpackChunkName: "components/icons-currency" */).then(c => wrapFunctional(c.default || c))
export const IconsPassportIcon = () => import('../../src/components/icons/passport-icon.vue' /* webpackChunkName: "components/icons-passport-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsReturnTicketIcon = () => import('../../src/components/icons/return-ticket-icon.vue' /* webpackChunkName: "components/icons-return-ticket-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsReturn = () => import('../../src/components/icons/return.vue' /* webpackChunkName: "components/icons-return" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
